<template>
    <Modal>
        <Card class="card p-4 text-darkPurple flex flex-col relative">
            <div @click="close" class="w-auto mb-5 h-auto cursor-pointer flex justify-end">
                <icon icon-name="flame-close" class="text-flame" size="s" />
            </div>
            <div class="w-full mx-auto my-5 flex justify-center items-center">
                 <img
                    src="@/assets/images/analytics.png"
                    style="width: 122px; height: 100px"
                />
            </div>

            <p class="w-full mx-auto text-center font-bold text-lg mb-5">Total Gross Breakdown</p>

            <div class="w-full p-4 flex flex-col gap-3 rounded bg-guyabano">
                <div class="w-full pb-3 flex justify-between items-center font-normal text-sm border-b border-solid border-romanSilver">
                    <p class="font-bold text-xs capitalize">total Gross</p>
                    <p>{{ totalGross ? convertToCurrency(totalGross) : convertToCurrency(0)  }}</p>
                </div>
                <div class="w-full flex justify-between items-center font-normal text-sm">
                    <p class="font-bold text-xs capitalize">total paye</p>
                    <p>{{ convertToCurrency(totalPaye) }}</p>
                </div>
                <div class="w-full flex justify-between items-center font-normal text-sm">
                    <p class="font-bold text-xs capitalize">total pension (employee)</p>
                    <p>{{ convertToCurrency(totalPension) }}</p>
                </div>
              <div class="w-full flex justify-between items-center font-normal text-sm">
                    <p class="font-bold text-xs capitalize">total pension (employer)</p>
                    <p>{{ convertToCurrency(totalOrgPension) }}</p>
                </div>
                <div class="w-full flex justify-between items-center font-normal text-sm">
                    <p class="font-bold text-xs capitalize">total nHF</p>
                    <p>{{ convertToCurrency(totalNHF) }}</p>
                </div>
                <div class="w-full pt-3 flex justify-between items-center font-normal text-sm border-t border-solid border-romanSilver">
                    <p class="font-bold text-xs capitalize">total net salary</p>
                    <p>{{ totalGross ? convertToCurrency(totalNet) : convertToCurrency(0)  }}</p>
                </div>
            </div>
        </Card>
    </Modal>
</template>

<script>
import Modal from "./Modal";
import Card from "./Card";

    export default {
        name: "ViewTotalGross",
        props: {
            totalGross: {
                type: Number,
                default: 0
            },
            totalNet: {
                type: Number,
                default: 0
            },
          totalPaye: {
                type: Number,
                default: 0
            },
            totalPension: {
                type: Number,
                default: 0
            },
          totalOrgPension: {
                type: Number,
                default: 0
            },
            totalNHF: {
                type: Number,
                default: 0
            },
        },
        components: {
            Modal,
            Card,
        },
        data() {
            return {};
        },
        methods:{
            close(){
                this.$emit("close");
            },
        }
    };
</script>

<style scoped>
.card{
    width: 400px;
}
</style>
